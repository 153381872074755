import Header from "components/Header/Header";
import Teaser from "components/Teaser/Teaser";
import Wochen from "components/Test/Wochen";
import Navbar from "components/Navbar/Navbar";
import useTranslation from "lib/hooks/useTranslation";
import React, { useEffect, useRef, useState } from "react";
import { StyledContainer } from "styles/uielements";
import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import Rad from "components/Rad/Rad";
import { SCREENS } from "lib/constants";
import Railtour from "components/Railtour/Railtour";
import Sofortpreis from "components/Sofortpreis/Sofortpreis";
import Schade from "components/Schade/Schade";
import NewForm from "components/Formular/NewForm";
import Hauptpreise from "components/Hauptpreise/Hauptpreise";
import Sofortpreise from "components/Sofortpreise/Sofortpreise";
import Sortiment from "components/Sortiment/Sortiment";
import Footer from "components/Footer/Footer";
import Menue from "components/Menue/Menue";
import Stoerer from "components/Stoerer/Stoerer";
import SkiChallenge from "components/SkiChallenge/SkiChallenge";
import Clip from "components/Clip/Clip";
import { useMediaQuery } from "react-responsive";
import Fragen from "components/Fragen/Fragen";
import Danke from "components/Danke/Danke";
import CookieBanner from "components/CookieBanner/CookieBanner";
import Cards from "components/Cards/Cards";
import ProduktTeaser from "components/ProduktTeaser/ProduktTeaser";
import Slider from "components/Slider/Slider";
import SpotifyTeaser from "components/SpotifyTeaser/SpotifyTeaser";
import Countdown from "components/Countdown/Countdown";
import Ende from "./Ende";
import Endverlosung from "components/Ende/Endverlosung";

const Startseite = ({ language }) => {
  const { setLanguage } = useTranslation();
  const { state, dispatch } = useAppState();
  const preiseRef = useRef();
  const sofortpreiseRef = useRef();
  const containerRef = useRef();
  const { t } = useTranslation();
  //const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setLanguage(language);
    window.cl2022_language = language;
  }, [language, setLanguage]);

  useEffect(() => {
    if (state.clickedMenuItem === t("menu_hauptpreise")) {
      console.log("preiseRef ", preiseRef);
      preiseRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
    if (state.clickedMenuItem === t("menu_sofortpreise")) {
      console.log("sofortpreiseRef ", sofortpreiseRef);
      sofortpreiseRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }

    // Wieder auf Null setzten damit bei erneutem Anwahl des Preise Menues wieder gescrollt wird
    dispatch({
      type: ACTIONS.SET_CLICKEDMENUITEM,
      payload: "",
    });
  }, [state.clickedMenuItem, t, dispatch]);

  const [showCookieBanner, setShowCookieBanner] = useState(
    "eemny23cookiesAllowed"
  );

  useEffect(() => {
    const local = localStorage.getItem("eemny23cookiesAllowed");
    console.log(local);
    setShowCookieBanner(local);
  }, [showCookieBanner]);

  const handleCookieClick = () => {
    setShowCookieBanner("eemny23cookiesAllowed");
    localStorage.setItem("eemny23cookiesAllowed", "eemny23cookiesAllowed");
  };

  return (
    <>
      {state.overlayMenuVisible && <Menue></Menue>}
      <div ref={containerRef}></div>
      <div className="max-w-[1440px] mx-auto">
        {showCookieBanner !== "eemny23cookiesAllowed" && (
          <CookieBanner onClick={handleCookieClick}></CookieBanner>
        )}

        <Navbar></Navbar>
        {state.screen === SCREENS.STARTSEITE && (
          <>
            <Header typ="standard"></Header>
            <div className="max-w-[912px] mx-auto">
              {/* <Endverlosung></Endverlosung> */}
              <div className="mb-16"></div>

              {/* <Countdown></Countdown> */}
              {state.week > 400 ? (
                <>
                  <Endverlosung></Endverlosung>
                  <div className="mb-16"></div>
                </>
              ) : (
                <>
                  <Teaser></Teaser>
                  <div ref={preiseRef}></div>
                  <Hauptpreise></Hauptpreise>
                  <div ref={sofortpreiseRef}></div>
                  <Sofortpreise></Sofortpreise>
                  <div className="mb-28 md:mb-4"></div>
                  <ProduktTeaser></ProduktTeaser>
                  <div className="mt-8"></div>
                  <SpotifyTeaser></SpotifyTeaser>
                  <Slider></Slider>
                  <div className="mb-16"></div>
                </>
              )}

              {/* <Cards></Cards> */}
            </div>
            <Footer></Footer>
          </>
        )}

        {state.screen === SCREENS.FORMULAR && (
          <>
            <div className="max-w-[912px] mx-auto">
              <Fragen></Fragen>
            </div>
            <Footer></Footer>
          </>
        )}
        {state.screen === SCREENS.GEWINN && (
          <>
            <Header typ="standard"></Header>
            <div className="max-w-[912px] mx-auto">
              <Sofortpreis></Sofortpreis>
              <div className="mb-28"></div>
              <ProduktTeaser></ProduktTeaser>
              <div className="mt-20"></div>
              <SpotifyTeaser></SpotifyTeaser>
              <div className="mb-16"></div>
              <Slider></Slider>
            </div>
            <Footer></Footer>
          </>
        )}
        {state.screen === SCREENS.DANKE && (
          <>
            <Header typ="standard"></Header>
            <div className="max-w-[912px] mx-auto">
              <Danke></Danke>
              <div className="mb-28"></div>
              <ProduktTeaser></ProduktTeaser>
              <div className="mt-20"></div>
              <SpotifyTeaser></SpotifyTeaser>
              <div className="mb-16"></div>
              <Slider></Slider>
              {/* <Cards></Cards> */}
            </div>
            <Footer></Footer>
          </>
        )}
        {state.screen === SCREENS.SCHADE && (
          <>
            <Header typ="standard"></Header>
            <div className="max-w-[912px] mx-auto">
              <Schade></Schade>
              <div className="mb-28"></div>
              <ProduktTeaser></ProduktTeaser>
              <div className="mt-20"></div>
              <SpotifyTeaser></SpotifyTeaser>
              <div className="mb-16"></div>
              <Slider></Slider>
              {/* <Cards></Cards> */}
            </div>
            <Footer></Footer>
          </>
        )}
      </div>
    </>
  );
};

export default Startseite;
