import { PATTERN_EMAIL } from "helper/constants";
import { SCREENS } from "lib/constants";
import { readData, updateData } from "lib/fetchData";
import useApiURL from "lib/hooks/useApiURL";
import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import {
  useFormularState,
  ACTIONS as FORMACTIONS,
} from "lib/hooks/useFormularState";
import useTranslation from "lib/hooks/useTranslation";
import React, { useState } from "react";

const Teaser = () => {
  const { t } = useTranslation();
  const { dispatch } = useAppState();
  const { formState, formDispatch } = useFormularState();
  const [fehler, setFehler] = useState("");

  const { base_url } = useApiURL();

  const validate = () => {
    if (formState.email === "") {
      setFehler(t("formular_fehler_email"));
      return false;
    }
    if (!PATTERN_EMAIL.test(formState.email)) {
      setFehler(t("formular_fehler_email"));
      return false;
    }

    return true;
  };

  const handleClick = async () => {
    // validate
    if (validate()) {
      const data = {
        ...formState,
      };
      const response = await readData(data, base_url);
      console.log("updateData Response ", response);

      // Wenn der User existiert und KEIN Sofortpreis gewonnen wurde:
      if (
        response.payload.existingUser === 1 &&
        response.payload.typ === "WETTBEWERB"
      ) {
        dispatch({
          type: ACTIONS.SET_SCREEN,
          payload: SCREENS.SCHADE,
        });
      }

      // Wenn der User existiert und Sofortpreis gewonnen wurde:
      if (
        response.payload.existingUser === 1 &&
        response.payload.typ !== "WETTBEWERB"
      ) {
        dispatch({
          type: ACTIONS.SET_SCREEN,
          payload: SCREENS.GEWINN,
        });
      }

      // Wenn der User NICHT existiert
      if (response.payload.existingUser !== 1) {
        dispatch({
          type: ACTIONS.SET_SCREEN,
          payload: SCREENS.FORMULAR,
        });
      }
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    setFehler("");
    formDispatch({
      type: FORMACTIONS.SET_DATA,
      payload: {
        key: "email",
        value: event.target.value,
      },
    });
  };

  return (
    <>
      <div className="text-3xl leading-10 md:text-[56px] md:leading-[67px] font-extrabold italic mt-8 md:mt-24 px-8">
        {t("startseite_titel")}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: t("startseite_text") }}
        className="text-lg leading-7 md:text-[22px] md:leading-8 px-8 mt-4 md:mt-8"
      ></div>
      <div className="px-8">
        <input
          type="email"
          onChange={handleChange}
          name="email"
          value={formState.email}
          placeholder="*E-Mail"
          className="bg-white mx-auto block md:inline form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0 md:mt-8"
        ></input>
      </div>
      <div className="text-lg leading-7 px-8 mt-4">
        {t("startseite_pflichtfeld")}
      </div>
      {fehler !== "" && (
        <div className="text-lg leading-7 px-8 mt-4 text-yellow-300">
          {fehler}
        </div>
      )}
      <div className="px-8 mt-8">
        <button
          className="italic font-extrabold uppercase text-lg text-[#000] bg-[#FFED00] px-8 py-4"
          onClick={handleClick}
        >
          {t("startseite_btn")}
        </button>
      </div>
    </>
  );
};

export default Teaser;
